import axios from "axios";
import { SERVER } from "../config";
import { generateConfig } from "../utils/generateConfig";

const AUTHENTICATION_URL = `${SERVER}/authentication`;

const userLogin = async (email, password) => {
  const data = {
    email: email,
    password: password,
  };
  try {
    const res = await axios.post(`${AUTHENTICATION_URL}/login`, data);
    return res.data;
  } catch (err) {
    return err.response.data.error;
  }
};

const validate = async (token) => {
  const data = {
    access_token: token,
  };
  try {
    const res = await axios.post(`${AUTHENTICATION_URL}/validate`, data);
    return res.data;
  } catch (err) {
    return err.response.data.error;
  }
};

const addUser = async (user, token) => {
  const config = generateConfig(token);
  try {
    const res = await axios.post(
      `${AUTHENTICATION_URL}/register`,
      user,
      config
    );
    return res.data;
  } catch (err) {
    return err.response.data.error;
  }
};

const getAllUsers = async (token) => {
  const config = generateConfig(token);
  try {
    const res = await axios.get(`${AUTHENTICATION_URL}/all`, config);
    return res.data;
  } catch (err) {
    return err.response.data.error;
  }
};

const getAllUsersByCompany = async (company, token) => {
  const config = generateConfig(token);
  try {
    const res = await axios.get(
      `${AUTHENTICATION_URL}/all?company=${company}`,
      config
    );
    return res.data;
  } catch (err) {
    return err.response.data.error;
  }
};

const getOneUser = async (id, token) => {
  const config = generateConfig(token);
  try {
    const res = await axios.get(`${AUTHENTICATION_URL}/get/${id}`, config);
    return res.data;
  } catch (err) {
    return err.response.data.error;
  }
};

const editUser = async (id, newUser, token) => {
  const config = generateConfig(token);
  try {
    const res = await axios.put(
      `${AUTHENTICATION_URL}/update/${id}`,
      newUser,
      config
    );
    return res.data;
  } catch (err) {
    return err.response.data.error;
  }
};

const deleteUser = async (id, token) => {
  const config = generateConfig(token);
  try {
    const res = await axios.delete(`${AUTHENTICATION_URL}/${id}`, config);
    return res.data;
  } catch (err) {
    return err.response.data.error;
  }
};

export {
  userLogin,
  validate,
  addUser,
  getOneUser,
  getAllUsers,
  getAllUsersByCompany,
  editUser,
  deleteUser,
};
