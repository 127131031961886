import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import AdminGuard from './components/AdminGuard'
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';


const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const LoginPage = Loadable(lazy(() => import('./pages/authentication/LoginPage')));


// Channel pages
const ChannelList = Loadable(lazy(() => import('./pages/dashboard/channel/ChannelList')))
const ChannelNew = Loadable(lazy(() => import('./pages/dashboard/channel/ChannelNew')))
const ChannelEdit = Loadable(lazy(() => import('./pages/dashboard/channel/ChannelEdit')))

// Company pages
const CompanyList = Loadable(lazy(() => import('./pages/dashboard/company/CompanyList')))
const CompanyNew = Loadable(lazy(() => import('./pages/dashboard/company/CompanyNew')))
const CompanyEdit = Loadable(lazy(() => import('./pages/dashboard/company/CompanyEdit')))

// Site pages
const SiteList = Loadable(lazy(() => import('./pages/dashboard/site/SiteList')))
const SiteNew = Loadable(lazy(() => import('./pages/dashboard/site/SiteNew')))
const SiteEdit = Loadable(lazy(() => import('./pages/dashboard/site/SiteEdit')))

// Terminal pages
const TerminalList = Loadable(lazy(() => import('./pages/dashboard/terminal/TerminalList')))
const TerminalEdit = Loadable(lazy(() => import('./pages/dashboard/terminal/TerminalEdit')))
const TerminalNew = Loadable(lazy(() => import('./pages/dashboard/terminal/TerminalNew')))

// User pages
const UserList = Loadable(lazy(() => import('./pages/dashboard/user/UserList')))
const UserEdit = Loadable(lazy(() => import('./pages/dashboard/user/UserEdit')))
const UserNew = Loadable(lazy(() => import('./pages/dashboard/user/UserNew')))

// Analytics pages
const UsagePage = Loadable(lazy(() => import('./pages/dashboard/analytics/UsagePage')))
const QoSPage = Loadable(lazy(() => import('./pages/dashboard/analytics/QoSPage')))


// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));




const routes = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <LoginPage />
      </GuestGuard> 
    )
  },
  {
    path: '401',
    element: <AuthorizationRequired />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <ServerError />
  },
  {
    path: '*',
    element: <NotFound />
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'QoS',
        element: <QoSPage />
      },
      {
        path: 'usage',
        element: <UsagePage />
      },
      {
        path: 'channel',
        children: [
          {
            path: '/',
            element: <ChannelList />
          },
          {
            path: 'create',
            element: <ChannelNew />
          },
          {
            path: ':channelId/edit',
            element: <ChannelEdit />
          }
        ]
      },
      {
        path: 'site',
        children: [
          {
            path: '/',
            element: <SiteList />
          },
          {
            path: 'create',
            element: <SiteNew />
          },
          {
            path: ':siteId/edit',
            element: <SiteEdit />
          }
        ]
      },
      {
        path: 'terminal',
        children: [
          {
            path: '/',
            element: <TerminalList />
          },
          {
            path: 'create',
            element: <TerminalNew />
          },
          {
            path: ':terminalId/edit',
            element: <TerminalEdit />
          }
        ]
      },
      {
        path: 'user',
        children: [
          {
            path: '/',
            element: <UserList />
          },
          {
            path: 'create',
            element: <UserNew />
          },
          {
            path: ':userId/edit',
            element: <UserEdit />
          }
        ]
      },
      {
        path: 'company',
        children: [
          {
            path: '/',
            element: (
            <AdminGuard>
              <CompanyList />
            </AdminGuard>
            )
          },
          {
            path: ':companyId',
            element: <CompanyNew />
          },
          {
            path: ':companyId/edit',
            element: <CompanyEdit />
          }
        ]
      }
    ]
  },
];

export default routes;
