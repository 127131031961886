import React, { createContext, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { validate, userLogin, addUser } from "../api/authentication" 

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  message: '',
  error: null,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, error } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      error
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { message } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      message,
    };
  },
  ERROR: (state, action) => {
    const { errorMessage } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      error: errorMessage
    }
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create context
export const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});
// Provider component
export const AuthProvider = ({ children }) => {
  // const [user, setUser] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken) {
          const res = await validate(accessToken)
          const { data } = res
          const user = data.user
          const error = null

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
              error
            },
          });
        } 
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const login = async (email, password) => {
    const res = await userLogin(email, password)
    const { status } = res
    if (status === "success") {
      const { data } = res
      const accessToken = data.login.accessToken
      const user = data.user
      const error = null
  
      localStorage.setItem('accessToken', accessToken)
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          error
        }
      });
    } else if (status === "error") {
      const { error } = res
      const errorMessage = error.error.text
      dispatch({ 
        type: 'ERROR',
        payload: {
          errorMessage
        }
      })
    }
    
  }

  const logout = async () => {
    localStorage.removeItem('accessToken')
    dispatch({ type: 'LOGOUT' });
  }

  const register = async (fullName, email, password, companyId, accessLevelId) => {
    const user = {
      "full_name": fullName,
      "email": email,
      "password": password,
      "company_id": companyId,
      "access_level_id": accessLevelId
    }
    const accessToken = localStorage.getItem('accessToken')
    const res = await addUser(user, accessToken);

    const message = res.message

    dispatch({
      type: 'REGISTER',
      payload: {
        message
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
