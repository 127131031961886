import React from 'react';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import { useRoutes } from 'react-router-dom'
import { useScrollReset, useSettings }  from './hooks';
import routes from './routes';
import { createCustomTheme } from './theme';



const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-center" />
        {content}
    </ThemeProvider>
  )
}

export default App;
