import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, Link, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAuth } from '../../hooks';
import BriefcaseIcon from '../../icons/Briefcase';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import DeviceTablet from '../../icons/DeviceTablet'
import Camera from '../../icons/Camera'
import UserIcon from '../../icons/User';
import Collection from '../../icons/Collection';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

const sections = [
  {
    title: 'Analytics',
    items: [
      {
        title: 'QoS',
        path: '/dashboard/QoS',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      {
        title: 'Usage',
        path: '/dashboard/usage',
        icon: <ChartPieIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Configurations',
    items: [
        {
            title: 'Channel',
            path: '/dashboard/channel',
            icon: <Camera fontSize="small" />,
          },
          {
            title: 'Site',
            path: '/dashboard/site',
            icon: <Collection fontSize="small" />,
          },
          {
            title: 'Terminal',
            path: '/dashboard/terminal',
            icon: <DeviceTablet fontSize="small" />,
       },
      {
        title: 'Account',
        path: '/dashboard/user',
        icon: <UserIcon fontSize="small" />,
      },
      {
        title: 'Company',
        icon: <BriefcaseIcon fontSize="small" />,
        path: '/dashboard/company',
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, openMobile, onMobileClose]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/" style={{ textDecoration: "none" }}>
          <Typography
                variant="h5"
                color="primary"
                sx={{
                  display: {
                    lg: "inline",
                  },
                }}
              >
                IPTV Dashboard
              </Typography>
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              {user && 
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.full_name}
              </Typography>
              }
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {user.company_id === 1 && sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
          {user.company_id !== 1 && sections.filter((section) => {
            return section.title !== "Company"
          }).map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
          
        </Box>
        {user && user.company_id !== 1 && 
        <>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Need Help?
              </Typography>
              <Link
                href="https://www.rally.ca/wp/contact/"
                target="_blank"
                rel="noopener noreferrer"
              >
              <Button
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                variant="contained"
              >
                Contact Us
              </Button>
              </Link>
            </Box>
            </>
        }
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  user: PropTypes.object.isRequired
};

export default DashboardSidebar;
