import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress, Typography } from '@material-ui/core';

const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#F4F5F7',
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        }}
    >
      <CircularProgress color="primary" sx={{marginRight: '10px'}}/>
      <Typography
      color="textPrimary"
      variant="h5"
      >
        Loading...
      </Typography>
    </Box>
  );
};

export default LoadingScreen;
